import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Loading from "../../shared-components/loading/Loading";
//global states
import { useGlobalStore } from "../../../../utils/global-store/vo/Vo-globalStore";
import defaultIcon from "../../../../images/brand/15.svg";

//services
import WarrantyServices from "../../../../services/vo/warranty-service";

const MileagePopup = (props) => {
  const [globalVariable] = useGlobalStore(false);
  const {
    moduleData,
    selectedVehicle,
    OnManageCoverageClick,
    onCloseMilagePopup,
  } = props;
  const [mileage, setMileage] = useState(0);
  const [imageUrl, setImageUrl] = useState(null);
  const [loading, SetLoading] = useState(false);
  const [mileageerrormessage, SetMileageErrorMessage] = useState(false);
  const [nextButtonActive, SetNextButtonActive] = useState(false);
  const history = useHistory();
  useEffect(() => {
    setImageUrl(
      require(`../../../../images/brand/${selectedVehicle.BrandGroupId}.svg`)
    );
  }, []);

  const onMilageChange = (value) => {
    setMileage(value);
  };

  const onClickMilageSubmit = async () => {
    SetMileageErrorMessage(false);
    if (mileage != 0) {
      SetLoading(true);
      var result = await WarrantyServices.ValidateMillage(
        selectedVehicle.regNo,
        mileage
      );
      if (result.data.status != true) {
        SetMileageErrorMessage(true);
        SetLoading(false);
      } else {
        const params = new URLSearchParams(window.location.search);
        history.push(
          `${globalVariable.moduleData.product_page_url}?vehicle_no=${btoa(
            selectedVehicle.regNo
          )}&mileage=${btoa(mileage)}&lang=${params.get("lang")}`
        );
      }
    }
  };

  return (
    <div id="popup1" class="overlay mileage-popup">
      <div class="popup">
        {loading ? <Loading></Loading> : ""}
        <a class="close" onClick={(e) => onCloseMilagePopup(e)} href="#">
          &times;
        </a>
        <div class="content">
          <div class="popup-header">
            <div className="header-inner">
              <div className="icon-bg">
                <img
                  src={imageUrl}
                  alt="banner-image"
                  onError={(e) => {
                    e.target.src = defaultIcon;
                  }}
                ></img>
              </div>
              <div className="modal-title-wrapper">
                <h4 className="modal-title">{selectedVehicle.regNo}</h4>
                <h5 className="modal-sub-title">
                  {selectedVehicle.brandName}, {selectedVehicle.modelName}
                </h5>
              </div>
            </div>
          </div>
          <div class="popup-wrap p-20">
            <div class="popup-body mb-20">
              {/* <h2>{moduleData.welcome_page_milagepopup_enter_milage}</h2> */}
              <div class="flex column">
                <label class="input-label mb-5">
                  {moduleData.welcome_page_milagepopup_milagetext}
                </label>
                <div className="flex v-center mob-block">
                  <input
                    class="input-style"
                    placeholder={
                      moduleData.welcome_page_milagepopup_milage_placeholder
                    }
                    type="number"
                    inputmode="numeric"
                    onChange={(e) => onMilageChange(e.target.value)}
                    onInput={(e) => {
                      e.target.value = e.target.value
                        ? Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 7)
                        : null;
                    }}
                  />
                </div>
                {mileageerrormessage ? (
                  <span className="error mt-20">
                    {" "}
                    {
                      globalVariable.moduleData
                        ?.welcome_page_milagepopup_milage_errormessage
                    }
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div class="popup-footer">
              {mileage != null && mileage > 0 ? (
                <button class="btn-primary" onClick={onClickMilageSubmit}>
                  {" "}
                  {moduleData.welcome_page_milagepopup_next}
                </button>
              ) : (
                <button class="btn-disable" type="button" disabled>
                  {" "}
                  {moduleData.welcome_page_milagepopup_next}{" "}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MileagePopup;
